body {
  background: #FFFEFD;
}

:root {
  --ratio: 1.5;
	--s0: 1rem; 
   --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
}

* {
  box-sizing: border-box;
  
  padding: 0;
  margin: 0;
  word-wrap: break-word;
  --black: rgb(5,5,10);
  --headerSurface: #E8DDF0;
   --guideSurface: #FFC738;
  --red: red;
  --gray: rgb(80,80,80);
}

main {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  
}

p {
  font-size: var(--s0);
  line-height: 1.45;
  margin-top: var(--s-2);
}


h1 {
  font-size: var(--s2);
  margin-top: var(--s2);
  font-weight: 600;
}

h2 {
  font-size: calc(0.85 * var(--s1));
  margin-top: var(--s2);
  font-weight: 600
}

h3 {
  font-size: calc(0.8 * var(--s1));
  font-weight: 600;
  margin-top: var(--s0);
  margin-bottom: var(--s-1);
}

.markdown {
  padding-bottom: var(--s4);
}
.markdown .preview {
  margin-top: var(--s0);
}
.markdown .line-number {
  display: none;
  width: 0;
  height: 0;
  max-width: 0;
  max-height: 0;
}
.markdown em {
  text-decoration: underline;
}
.markdown .code-line-offset {
  margin-left: var(--s-1);
}

.markdown > pre, .markdown code {
  background: rgb(240,240,240);
  color: var(--gray);
}
.markdown > pre {
  padding: var(--s-1);
  margin: var(--s0) 0;
  max-width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.white-bg {
  background: white;
}
.header-surface {
  background: var(--headerSurface);
  --black: #1D1225;
}
.edit-surface {
  background: #DCFBF2;
  --gray: #0B4836;
}

.edit-surface-light-tone {
  background: #F4FFFD;
  --gray: #8FB6AA;
}
.edit-surface-gray-tone {
  background: #EBF5F4;
  --gray: #8FB6AA;
  color: #64897E;
}
.guide-surface {
  background: var(--guideSurface);
}
.guide-surface-light-tone {
  background: var(--guideSurfaceLightTone);
}

em {
  line-height: 200%;
  font-style: inherit;
  font-size: 100%;
}
.blue {
  color: blue;
}
.gray {
  color: var(--gray);
}
.bold {
  font-weight: 600;
}
.full-bleed {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.full-size {
  width: 100%;
  height: 100%;
}


.narrow {
  max-width: 72ch;
}

.narrow.centered {
  margin: 0 auto;
}




.canvas-border canvas, .black-border {
  border: 1px solid var(--black);
}
/* 
.side-by-side  canvas {
  width: 100% !important;
  height: 100% !important;
} */

.flex-1 {
  flex: 1;
}

.border {
  border: 1px solid var(--black);
}

.border-bottom {
  border-bottom: 1px solid var(--black);
}
.border-bottom\:light{
  border-bottom: 1px solid var(--gray);
}

.border-right {
  border-right: 1px solid var(--black);
}
.border-left {
  border-left: 1px solid var(--black);
}
.border-top {
  border-top: 1px solid var(--black);
}
.red {
  color: var(--red);
}
.red-border {
  border-color: var(--red);
}
.green-border {
  border-color: rgb(0, 255, 0);
}

.subtext {
  font-size: calc(0.8  * var(--s0));
  padding-top: calc(0.2 * var(--s0));
}

.padded {
  padding: var(--s0);
}
.padded\:vertical {
  padding-top: var(--s0);
  padding-bottom: var(--s0);
}
.padded\:horizontal {
  padding-top: var(--s0);
  padding-bottom: var(--s0);
}
.padded\:right {
  padding-right: var(--s0);
}
.padded\:left {
  padding-left: var(--s0);
}
.padded\:left\:large {
  padding-left: var(--s1);
}
.padded\:left\:smallest {
  padding-left: var(--s-2);
}

.grid {
  display: flex;
  flex-wrap: wrap;
  --gridSpacing: var(--s0);
  align-items: stretch;
}


.grid > * {
  flex-basis: calc(50%);
  padding-bottom: var(--gridSpacing);
}

.grid > *:nth-child(2n) {
  padding-left: var(--gridSpacing);
}


ul, ol {
  padding-left: var(--s1);
}

.hide-overflow {
  overflow: hidden;
}
.visible-overflow {
  overflow: visible;
}
.full-size.hide-overflow {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.wrap-text {
  white-space: normal;
}

.wrap-text p {
  display: inline;
  
}
.clickable {
  cursor: pointer;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.stack {
  display: flex;
  flex-direction: column;
}

.stack > * + *{
  margin-top: var(--s0);
}

.stack > .dont-grow {
  flex-grow: 0;
}

.stack.no-gap > * + * {
  margin-top: 0;
}

.sticky {
  position: sticky;
  position: -webkit-sticky; /* For Safari */
  top: var(--s0); /* How far down the page you want your ToC to live */
  /* Give table of contents a scrollbar */
  max-height: calc(100vh - 40px);
  overflow: auto;
}


.stack.large > * + * {
  margin-top: var(--s5);
}

.stack.small > * + * { 
  margin-top: var(--s-1);
}
.stack.smallest > * + * {
  margin-top: var(--s-2);
}

.horizontal-stack {
  display: flex;
  flex-direction: row;
  --spacingLeft: var(--s0);
}


.horizontal-stack.no-gap {
  --spacingLeft: 0;
}
.horizontal-stack > * {
  display: inline-flex;
}

.horizontal-stack.large {
  --spacingLeft: var(--s2)
}
.horizontal-stack.small {
  --spacingLeft: var(--s-1)
}
.horizontal-stack.smallest {
  --spacingLeft: var(--s-2)
}
.horizontal-stack > * + * {
  margin-left: var(--spacingLeft);
}



.center-text {
  text-align: center;
}


.side-by-side {
  display: flex;
  flex-direction: row;
}

.side-by-side > * {
  flex-basis: 50%;
  max-width: 50%;
}
.side-by-side li  {
  word-wrap: break-word;
}
.side-by-side .sticky {
  position: sticky;
  top: 0px;
  left: 0px;
  align-self: flex-start;
}

.scrollable {
  overflow: scroll;
}
.padded .sticky {
  top: 1em;
  left: 1em;
}
.floating {
  position: absolute;
  z-index:  2;
}
.label {
  font-size: var(--s-1);
  position: absolute;
  top: var(--s-2);
}
.button {
  text-decoration: underline;
}
.floating-button {
  position: absolute;
  top: 1em;
  right: 1em;
}
.become-visible {
  animation-name: fadein;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.code-text > * {
  position: relative;
}

pre, .code-text {
  font-family: monospace;
  font-size: var(--s0);
  overflow: visible;
}

.code-line-offset {
  margin-left: 4ch;
}
.code-text textarea:focus {
  outline: none !important;
  
}
.code-line-offset .line-number {
  position: absolute;
  left: calc(-3 * 1ch);
  color: var(--gray);
}
.code-text .display-as-error {
  text-decoration: underline;
  text-decoration-color: var(--red);  
}

.code-text .display-as-error .line-number{
  color: var(--red);
}

.code-text .display-as-error::before {
  content: "•";
  color: var(--red);
  position: absolute;
  left: calc( -1 * 1ch);
}

.Dropdown-root .Dropdown-arrow-wrapper {
  display: inline;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

a , a:visited{
  text-decoration: none;
  color: inherit
}

/* SPECIFIC ELEMENTS */

.header-link {
  position: relative;
  left: var(--s-2);
}
.header-link::before {
  content: "↗";
  position: relative;
  left: calc(-1 * var(--s-2))
}

.header-link.active::before {
  content: "⚘"
}

.toolbar input {
  padding: 0;
  margin: 0;
  width: 10ch;
}


.toolbar > * {
  padding: var(--s0) 0;
  padding-right: var(--s0);
  border-right: 1px solid var(--black);
}
.toolbar > *:first-child {
  padding-left: var(--s0);
}
.input-track {
  width: calc(100% - var(--s-1));
  height: 1px;
  border: none;
  background: black;
  border-radius: var(--s0);
}
.toolbar .input-track {
  position: relative;
  top: calc(0.5 * var(--s0));
}
.input-thumb {
  width: var(--s-1);
  height: var(--s-1);
  border-radius: 50%;
  background: white;
  border: 1px solid var(--black);
  outline: none;
}

.gfx-controls label {
  width: 15ch;
  height: 100%;
  align-items: center;
}
.gfx-controls  input {
  width: 100%;
  height: var(--s2);
  padding: 0.5em;
  outline: none;
  border: none;
  border: 1px solid rgb(240, 240, 250);
}
.gfx-controls > *:nth-child(odd) {
  border-right: 1px solid var(--gray);
}
.gfx-controls > * {
  border-bottom: 1px solid var(--gray);
  padding: var(--s0);
  display: flex;
  align-items: center;
  height: calc(var(--s3) + var(--s-1));
}
.gfx-controls > *:last-child {
  border-bottom: none;
}

.console-height {
  height: 15ch;
  overflow-y: auto;
}